<template>
  <div>
    <div class="filters-row df">
      <h2>{{ $t('authorisations') }}</h2>
      <input
        id="table-filter"
        ref="filter"
        style="width: 200px"
        type="text"
        class="form-control"
        :placeholder="$t('filterTable')"
        @keyup="filterTable"
      />
    </div>
    <form ref="table" class="table-responsive" @submit.prevent="null">
      <table class="table-style table-settings table_sort">
        <thead>
          <tr>
            <th>{{ $t('organisationIdentifier') }}</th>
            <th>{{ $t('description') }}</th>
            <th>
              <span
                class="btn btn-secondary"
                @click="showAddAuthorisation"
                >+ {{ $t('add') }}</span
              >
            </th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(authorisation, index) in authorisations">
            <tr
              :key="index"
              :data-val="authorisation.organisationIdentifier"
            >
              <td :data-label="$t('organisationIdentifier') + ':'">
                {{ authorisation.organisationIdentifier }}
              </td>
              <td :data-label="$t('description') + ':'">
                {{ authorisation.description }}
              </td>
              <td data-label class="text-right">
                <span
                  class="link-edit text-primary"
                  @click="showEditAuthorisation(index)"
                >
                  <font-awesome-icon icon="edit" />
                  {{ $t('edit') }}
                </span>
                <br />
                <span
                  class="link-remove"
                  @click="
                    ;(currentIndex = index),
                      $bvModal.show('modal-remove')
                  "
                >
                  <font-awesome-icon icon="times" />
                  {{ $t('remove') }}
                </span>
                <br />
              </td>
            </tr>
          </template>
          <div v-if="!authorisations.length">
            <tr>
              <td>
                <strong>{{ $t('noRecordsFound') }}</strong>
              </td>
            </tr>
          </div>
        </tbody>
      </table>
    </form>
    <b-modal
      id="modal-remove"
      :on-enter-key-press="() => removeAuthorisation(currentIndex)"
      hide-footer
    >
      <template v-slot:modal-title>
        <strong>{{ $t('remove') }}</strong>
      </template>
      <div class="d-block text-center">
        <p>{{ $t('deleteConfirmation') }}</p>
        <b-button
          variant="danger"
          class="mt-1"
          @click="$bvModal.hide('modal-remove')"
        >
          {{ $t('no') }}
        </b-button>
        <b-button
          class="mt-1"
          primary
          @click="removeAuthorisation(currentIndex)"
        >
          {{ $t('yes') }}
        </b-button>
      </div>
    </b-modal>
    <upsert-building-access-modal
      :selected-authorisation="selectedAuthorisation"
      :mode="mode"
    />
  </div>
</template>
<script>
import { mapState } from 'vuex'
import _cloneDeep from 'lodash/cloneDeep'

import { filterTableMixin } from '@/mixins/mixins'
import OkkuApi from '@/services/OkkuApi'
import {
  SET_ALERT,
  SET_AUTHORISATIONS
} from '@/store/modules/common/mutationTypes'

import BootstrapModalHOC from '@/components/hocs/BootstrapModal'
import UpsertBuildingAccessModal from '@/components/modals/UpsertBuildingAccessModal'

export default {
  name: 'AuthorisationsTable',
  components: {
    'b-modal': BootstrapModalHOC,
    UpsertBuildingAccessModal
  },
  mixins: [filterTableMixin],
  data: () => ({
    currentIndex: null,
    selectedAuthorisation: null,
    authorisations: [],
    mode: null
  }),
  computed: {
    ...mapState('common', {
      organisation: '$organisation',
      storeAuthorisations: 'authorisations',
      isDashboardAdmin: '$isDashboardAdmin'
    })
  },
  watch: {
    storeAuthorisations() {
      this.authorisations = _cloneDeep(this.storeAuthorisations)
    }
  },
  async created() {
    await this.getAuthorisations()
  },
  mounted() {
    this.authorisations = _cloneDeep(this.storeAuthorisations)
  },
  methods: {
    async getAuthorisations() {
      const authorisations = await OkkuApi.getAuthorisations()
      this.$store.commit(
        `common/${SET_AUTHORISATIONS}`,
        authorisations
      )
    },
    showAlert(payload) {
      this.updateAlert(payload.variant, payload.text)
    },
    updateAlert(variant, text) {
      this.$store.commit(`common/${SET_ALERT}`, {
        seconds: 3,
        text,
        variant: variant || 'success'
      })
    },
    async removeAuthorisation(index) {
      try {
        const selected = this.authorisations[index]
        await OkkuApi.deleteAuthorisation(
          selected.organisationIdentifier
        )
        this.$store.commit(
          `common/${SET_AUTHORISATIONS}`,
          this.authorisations.filter(
            auth =>
              auth.organisationIdentifier !==
              selected.organisationIdentifier
          )
        )
        this.$bvModal.hide('modal-remove')
      } catch (error) {
        console.error(error)
      }
    },
    showAddAuthorisation() {
      this.mode = 'create'
      this.selectedAuthorisation = null
      this.$bvModal.show('upsert-building-access-modal')
    },
    showEditAuthorisation(index) {
      this.mode = 'edit'
      this.selectedAuthorisation = this.authorisations[index]
      this.$bvModal.show('upsert-building-access-modal')
    }
  }
}
</script>
<style lang="scss" scoped>
.tip-icon::v-deep {
  .fa-question-circle {
    font-size: 16px;
    margin-right: 5px;
    cursor: pointer;
  }
}
table {
  input {
    display: inline-block;
    vertical-align: middle;
  }
}
</style>
