<template>
  <b-modal
    id="upsert-building-access-modal"
    :on-enter-key-press="() => upsertBuildingAccess(authorisation)"
    hide-footer
  >
    <template v-slot:modal-title>
      <strong>{{ $t('edit') }} {{ $t('buildingAccess') }}</strong>
    </template>
    <div class="d-block text-center">
      <div class="row">
        <div class="col text-left">
          <div class="form-group">
            <label>{{ $t('organisationIdentifier') }}:</label>
            <input
              v-model="authorisation.organisationIdentifier"
              :disabled="mode === 'edit'"
              type="text"
              class="form-control"
            />
            <label>{{ $t('description') }}:</label>
            <input
              v-model="authorisation.description"
              type="text"
              class="form-control"
            />
          </div>
        </div>
      </div>
    </div>
    <footer
      class="d-flex align-items-center justify-content-end mt-1"
    >
      <b-button
        variant="danger"
        @click="$bvModal.hide('upsert-building-access-modal')"
      >
        {{ $t('cancel') }}
      </b-button>
      <b-button
        class="ml-1"
        primary
        @click="upsertBuildingAccess(authorisation)"
      >
        {{ $t('save') }}
      </b-button>
    </footer>
  </b-modal>
</template>

<script>
import {
  SET_ALERT,
  UPDATE_AUTHORISATION,
  CREATE_AUTHORISATION
} from '@/store/modules/common/mutationTypes'
import OkkuApi from '@/services/OkkuApi'
import BootstrapModalHOC from '@/components/hocs/BootstrapModal'

export default {
  name: 'UpsertBuildingAccess',
  components: {
    'b-modal': BootstrapModalHOC
  },
  props: {
    selectedAuthorisation: {
      type: Object,
      required: false,
      default: () => ({
        organisationIdentifier: '',
        description: ''
      })
    },
    mode: {
      type: String,
      default: 'create'
    }
  },
  data: () => ({
    currentIndex: null,
    authorisation: {
      organisationIdentifier: '',
      description: ''
    },
    changesDone: false
  }),
  computed: {},
  watch: {
    selectedAuthorisation: {
      immediate: true,
      deep: true,
      handler(val) {
        this.authorisation =
          val != null
            ? JSON.parse(JSON.stringify(val))
            : {
                organisationIdentifier: '',
                description: ''
              }
      }
    }
  },
  methods: {
    validateForm() {
      console.log(this.authorisation)
    },
    async upsertBuildingAccess(authorisation) {
      try {
        const { organisationIdentifier, description } = authorisation
        await OkkuApi.upsertAuthorisation(
          organisationIdentifier,
          description
        )
        this.$bvModal.hide('upsert-building-access-modal')
        this.$store.commit(`common/${SET_ALERT}`, {
          seconds: 3,
          text: this.$t('changesSaved'),
          variant: 'success'
        })
        this.$store.commit(
          `common/${
            this.mode === 'edit'
              ? UPDATE_AUTHORISATION
              : CREATE_AUTHORISATION
          }`,
          authorisation
        )
        this.authorisation = {
          organisationIdentifier: '',
          description: ''
        }
      } catch (error) {
        let { message } = error
        console.error(error)
        if (error.response && error.response.data) {
          message = error.response.data
        }
        this.$store.commit(`common/${SET_ALERT}`, {
          seconds: 3,
          text: message,
          variant: 'danger'
        })
      }
    }
  }
}
</script>
