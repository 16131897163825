<template>
  <div class="page-container">
    <section class="section-settings">
      <div class="container">
        <div class="row justify-content-md-center">
          <div class="col-lg-12 col-sm-12">
            <b-alert
              dismissible
              fade
              :variant="alertVariant"
              :show="dismissCountDown"
              @dismiss-count-down="countDownChanged"
            >
              {{ alertText }}
            </b-alert>
            <AuthorisationsTable
              @updateAlert="updateAlert"
              @updateChanges="updateChanges"
            />
          </div>
        </div>
      </div>
    </section>
    <b-modal
      id="modal-route"
      :on-enter-key-press="routeProceed"
      hide-footer
      hide-title
    >
      <div class="d-block text-center">
        <p>{{ $t('changesNotSaved') }}</p>
        <b-button
          variant="danger"
          class="mt-1"
          @click="$bvModal.hide('modal-route')"
        >
          {{ $t('no') }}
        </b-button>
        <b-button class="mt-1" primary @click="routeProceed">
          {{ $t('yes') }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import AuthorisationsTable from '@/components/common/AuthorisationsTable'
import BootstrapModalHOC from '@/components/hocs/BootstrapModal'

export default {
  name: 'AuthorisationsPage',
  components: {
    AuthorisationsTable,
    'b-modal': BootstrapModalHOC
  },
  data() {
    return {
      dismissCountDown: 0,
      alertVariant: 'success',
      alertText: '',
      changesDone: false,
      nextPage: ''
    }
  },
  beforeRouteLeave(to, from, next) {
    if (!this.changesDone) {
      next()
    } else {
      next(false)
      this.nextPage = to.name
      this.$bvModal.show('modal-route')
    }
  },
  methods: {
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    updateAlert(args) {
      this.dismissCountDown = 3
      this.alertVariant = args.variant
      this.alertText = args.text
    },
    updateChanges(args) {
      this.changesDone = args
    },
    routeProceed() {
      this.changesDone = false
      this.$router.push({ name: this.nextPage }, () => {})
    }
  }
}
</script>
<style lang="scss" scoped></style>
